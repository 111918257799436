<!--  -->
<template>
  <div class="app-container remuanlysis">
    <div class="home_title">工资概况</div>
    <div class="header_box">
      <div class="box_item">
          <p class="box_title">计算人数</p>
          <p class="left_number">{{pageData.staffNumber || "0"}}<small>人</small></p>
        <div class="box_word" v-show="pageData.totalPayableValueChainRatio&&pageData.totalPayableValueChainRatio!='0'">
          <i :class="String(pageData.totalPayableValueChainRatio).includes('-')?'el-icon-bottom':'el-icon-top'" /><span>{{String(pageData.staffNumberChainRatio).includes('-')?'':"+"}}{{pageData.staffNumberChainRatio || "0"}}人</span>
        </div>
      </div>
      <div class="box_item">
        <p>应发工资总计</p>
        <p class="left_number">{{pageData.totalPayableValue || "0"}}<small>元</small></p>
        <div class="box_word" v-show="pageData.totalPayableValueChainRatio&&pageData.totalPayableValueChainRatio!='0'">
          <i :class="pageData.totalPayableValueChainRatio.includes('-')?'el-icon-bottom':'el-icon-top'" /><span>环比{{pageData.totalPayableValueChainRatio.includes('-')?'':"+"}}{{pageData.totalPayableValueChainRatio || "0"}}%</span>
        </div>
      </div>
      <div class="box_item">
          <p>实发工资总计</p>
          <p class="left_number">{{pageData.totalPaidValue || "0"}}<small>元</small></p>
        <div class="box_word" v-show="pageData.totalPayableValueChainRatio&&pageData.totalPayableValueChainRatio!='0'">
          <i :class="pageData.totalPaidValueChainRatio.includes('-')?'el-icon-bottom':'el-icon-top'"/><span>环比{{pageData.totalPaidValueChainRatio.includes('-')?'':"+"}}{{pageData.totalPaidValueChainRatio || "0"}}%</span>
        </div>
      </div>
      <div class="box_item">
          <p>月度人力成本</p>
          <p class="left_number">{{pageData.monthManpowerCost || "0"}}<small>元</small></p>
        <div class="box_word" v-show="pageData.totalPayableValueChainRatio&&pageData.totalPayableValueChainRatio!='0'">
          <i :class="pageData.monthManpowerCostChainRatio.includes('-')?'el-icon-bottom':'el-icon-top'"/><span>环比{{pageData.monthManpowerCostChainRatio.includes('-')?'':"+"}} {{pageData.monthManpowerCostChainRatio || "0"}}%</span>
        </div>
      </div>
    </div>
    <!-- 图表 -->
    <div class="echarts_box" v-if="left_seriesData&&right_seriesData">
      <div class="echarts_item">
        <!--<div class="echarts_title">{{currentYear}}年{{currentMoth}}月企业工作组成占比图</div>!-->
        <div id="left_Round"></div>
      </div>
      <div class="echarts_item">
        <!--<div class="echarts_title">{{currentYear}}年{{currentMoth}}月部门人力成本TOP8占比比图</div>!-->
        <div id="right_Round"></div>
      </div>
    </div>
    <section v-else>
      <div class="img_box">
          <img class="img_box_img"  src="@/assets/developping_images/developping.png">
      </div>
      <!-- 文字说明 -->
      <div class="desc_box">
        <span>暂无数据</span>
      </div>
    </section>
    <div></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: '',
      currentMoth: '',
      pageData: {
        staffNumber: '',
        staffNumberChainRatio: '',
        totalPayableValue: '',
        totalPayableValueChainRatio: '',
        totalPaidValue: '',
        totalPaidValueChainRatio: '',
        monthManpowerCost: '',
        monthManpowerCostChainRatio: '',
        payrollList: [
          // {
          //   name: "固定工资",
          //   money: 51111321
          // },
          // {
          //   name: "浮动工资",
          //   money: 35662210
          // }
        ],
        orgPayrollList: [
          // {
          //   name: "产品部",
          //   money: 51111321
          // },
          // {
          //   name: "运营部",
          //   money: 35662210
          // }
        ]
      },
      left_seriesData: [],
      right_seriesData: []
    };
  },
  created() {
    const stringArr = new Date().toLocaleDateString().split('/');

    this.currentYear = stringArr[0];
    if (stringArr[1] - 1 < 10) {
      this.currentMoth = `0${stringArr[1] - 1}`;
    } else if (stringArr[1] - 1 == 0) {
      this.currentYear = stringArr[0] - 1;
      this.currentMoth = '12';
    } else {
      this.currentMoth = stringArr[1] - 1;
    }
  },
  mounted() {
    // this.getChartsLeft();
    // this.getChartsRight();
    this.getData();
  },
  methods: {
    diffDate(param) {
      return param < 10 ? `0${param}` : `${param}`;
    },
    getData() {
      const left_Round = this.$echarts.init(
          document.getElementById('left_Round')
        );
        const right_Round = this.$echarts.init(
          document.getElementById('right_Round')
        );
        const left_legendData = [];
        const left_seriesData = [];
        const left_selectData = {};
        const right_selectData = {};
        const right_legendData = [];
        const right_seriesData = [];

      this.$axios.post('/v1/payroll/analysis', {
          request: {month: `${this.currentYear}-${this.currentMoth}`}
        })
        .then((res) => {
          if (res._responseStatusCode == 0) {
            this.pageData = res;
            // 企业工作组成占比图
            if (res.payrollList && res.payrollList.length > 0) {
              res.payrollList.forEach((item) => {
                left_legendData.push(item.name);
                if (Number(item.money) == '0') {
                  left_selectData[item.name] = false;
                }
                left_seriesData.push({ value: item.money, name: item.name });
              });
                this.left_seriesData = left_seriesData;
            }
            // 部门人力成本TOP8比图
            if (res.orgPayrollList && res.orgPayrollList.length > 0) {
              res.orgPayrollList.forEach((item) => {
                right_legendData.push(item.name);
                if (Number(item.money) == '0') {
                  right_selectData[item.name] = false;
                }
                right_seriesData.push({ value: item.money, name: item.name });
              });
              this.right_seriesData = right_seriesData;
            }
          }
        })
        .then(() => {
          // 左
          left_Round.setOption({
            title: {
              text: `${this.currentYear}年${this.currentMoth}月企业工作组成占比图`,
              left: '40px',
              textStyle: {
                fontSize: '14',
                fontWeight: '600'
              }
            },
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            color: [
              '#409EFF',
              '#37CDF6',
              '#7ED321',
              '#FBD233',
              '#FF2E65',
              '#A142FF',
              '#0450D2',
              '#3B2CD6',
              '#A77CFF',
              '#C200F4'
            ],
            legend: {
              orient: 'vertical',
              icon: 'circle',
              //   x: 'right',
              y: 'center',
              right: 50,
              tooltip: {
                show: true
              },
              data: left_legendData,
              selected: left_selectData
            },
            series: [
              {
                name: '占比',
                type: 'pie',
                radius: ['30%', '40%'],
                center: ['38%', '45%'],
                avoidLabelOverlap: true,
                label: {
                  show: true,
                  formatter: '{b}'
                  // formatter: "{b}\n{c} ({d}%)",
                  // emphasis: {
                  //   show: true,
                  //   textStyle: {
                  //     fontSize: "15",
                  //     fontWeight: "bold"
                  //   }
                  // }
                },
                labelLine: {
                  normal: {
                    show: true
                  }
                },
                data: left_seriesData
              }
            ]
          });
          // 右
          right_Round.setOption({
            title: {
              text: `${this.currentYear}年${this.currentMoth}月部门人力成本TOP8占比比图`,
              left: '40px',
              textStyle: {
                fontSize: '14',
                fontWeight: '600'
              }
            },

            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            color: [
              '#409EFF',
              '#37CDF6',
              '#7ED321',
              '#FF7371',
              '#FF7E00',
              '#FFD588',
              '#FF2E65',
              '#A142FF'
            ],
            legend: {
              orient: 'vertical',
              //   x: 'right',
              icon: 'circle',
              y: 'center',
              right: 60,
              tooltip: {
                show: true
              },
              data: right_legendData,
              selected: right_selectData
            },
            series: [
              {
                name: '占比',
                type: 'pie',
                radius: ['30%', '40%'],
                center: ['35%', '45%'],
                avoidLabelOverlap: true,
                label: {
                  show: true,
                  formatter: '{b}',
                  // formatter:'{b}\n{c} ({d}%)',
                  fontSize: '12'
                  // emphasis: {
                  //   show: true,
                  //   textStyle: {
                  //     fontSize: "16",
                  //     fontWeight: "bold"
                  //   }
                  // }
                },
                labelLine: {
                    show: true
                },
                data: right_seriesData
              }
            ]
          });
        });
    },
    getChartsLeft() {
      const left_Round = this.$echarts.init(
        document.getElementById('left_Round')
      );
      //   this.$axios
      //     .get("/v1/common/dict")
      // .then(res => {
      //   if (res) {

      //   }
      // })
      // .then(() => {
      //   left_Round.clear();
      //   left_Round.resize();
      // 饼状
      left_Round.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        color: [
          '#FF7371',
          '#FF7E00',
          '#FFC457',
          '#60C6BB',
          '#7DDCFF',
          '#1081FE',
          '#0450D2',
          '#3B2CD6',
          '#A77CFF',
          '#C200F4'
        ],
        legend: {
          orient: 'vertical',
          //   x: 'right',
          y: 'center',
          right: 60,
          tooltip: {
            show: true
          },
          data: ['固定工资', '浮动工资', '津补贴', '税前补发', '税后补发']
        },
        series: [
          {
            name: '占比',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['30%', '50%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              { value: 335, name: '固定工资' },
              { value: 310, name: '浮动工资' },
              { value: 234, name: '津补贴' },
              { value: 135, name: '加班费' },
              { value: 1548, name: '税前补发' },
              { value: 1548, name: '税后补发' }
            ]
          }
        ]
      });
      // })
    },
    getChartsRight() {
      const right_Round = this.$echarts.init(
        document.getElementById('right_Round')
      );
      //   this.$axios
      //     .get("/v1/common/dict")
      // .then(res => {
      //   if (res) {

      //   }
      // })
      // .then(() => {
      //   right_Round.clear();
      //   right_Round.resize();
      // 饼状
      right_Round.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        color: [
          '#FF7371',
          '#FF7E00',
          '#FFC457',
          '#60C6BB',
          '#7DDCFF',
          '#1081FE',
          '#0450D2',
          '#3B2CD6',
          '#A77CFF',
          '#C200F4'
        ],
        legend: {
          orient: 'vertical',
          //   x: 'right',
          y: 'center',
          right: 60,
          tooltip: {
            show: true
          },
          data: ['产品部', '运营部', '人事部', '市场部', '研发部']
        },
        series: [
          {
            name: '占比',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['30%', '50%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              { value: 335, name: '产品部' },
              { value: 310, name: '运营部' },
              { value: 234, name: '人事部' },
              { value: 135, name: '加班费' },
              { value: 1548, name: '市场部' },
              { value: 1548, name: '销售部' },
              { value: 5048, name: '研发部' }
            ]
          }
        ]
      });
      // })
    }
  }
};
</script>
<style lang='scss' scoped>
.remuanlysis {
  [class^=el-icon-] {
    color:#FF670F;
    margin-right: 5px;
  }
  .el-icon-top {
    color:#7ED321;
  }
  .home_title {
    font-size: 16px;
    padding-bottom: 30px;
    font-weight: bold;
    color: #484848;
    &:before {
      display: inline-block;
      width: 8px;
      height: 20px;
      background: #498df0;
      content: " ";
      border-radius: 5px;
      vertical-align: text-bottom;
      margin-right: 8px;
    }
  }
  .img_box {
    // width: 1200px;
    // min-height: 600px;
    // background-color: pink;
    text-align: center;
    height: 100%;
    .img_box_img {
      width: 30%;
      // background-color: red;
    }
  }
  .desc_box {
    text-align: center;
    color: #333333;
    font-size: 14px;
    padding-left: 25px;
    // padding: 10px 0;
  }
  .header_box {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e3e3e3;
    .box_item {
      width: 20%;
      text-align: center;
      font-size: 12px;
      color: #484848;
      // padding: 0 40px;
      margin: 40px 0;
      .box_title {
        font-size: 12px;
        color: #2B3642;
        letter-spacing: 0;
      }
      .box_word {
        font-size: 10px;
        margin-top: 14px;
        color: #2B3642;
      }
        .left_number {
          padding-top: 10px;
          font-size: 22px;
          color: #509FF0;
          letter-spacing: 0;
          small {
            font-size: 14px;
            color: #001800;
            line-height: 30px;
            margin-left: 5px;
          }
        }
      // }
    }
  }
  .echarts_box {
    display: flex;
    padding: 20px 0;
    .echarts_item {
      width: 50%;
      .echarts_title {
        font-size: 16px;
        padding-left: 8%;
        // color: #2b3642;
        color: #484848;
        font-weight: 600;
      }
    }
  }
  #left_Round,
  #right_Round {
    height: 450px;
    width: 90%;
  }
}
</style>
